"use client";

import { UserOperationSchema, type UserOperation } from "@/core/userop";
import { useSearchParams } from "next/navigation";
import { createContext, useContext, useEffect, useState } from "react";

const context = createContext<{
  op?: UserOperation;
  blockNumber?: string;
  chainId?: string;
  entryPointMethod?: string;
  setOp?: (op: UserOperation) => void;
}>({});

export function UserOperationProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [op, setOp] = useState<UserOperation | undefined>();
  const searchParams = useSearchParams();
  const params = searchParams.get("userOperation") as string | undefined;
  const chainId = searchParams.get("chainId") as string | undefined;
  const blockNumber = searchParams.get("blockNumber") as string | undefined;
  const entryPointMethod = searchParams.get("entryPointMethod") as string | undefined;

  useEffect(() => {
    try {
      const opValues = params && JSON.parse(params);
      setOp(opValues);
    } catch (e) {
      console.error("error", e);
    }
  }, [params]);
  return (
    <context.Provider value={{ op, blockNumber, chainId, entryPointMethod, setOp: (op: UserOperation) => setOp(op) }}>
      {children}
    </context.Provider>
  );
}
export function useUserOperation() {
  const { op, chainId, blockNumber, setOp } = useContext(context);
  if (!setOp)
    throw new Error(
      "useUserOperation must be used within a UserOperationProvider",
    );
  return { op, chainId, blockNumber, setOp };
}

export function useUserOperationQuery() {
  const searchParams = useSearchParams();
  const params = searchParams.get("op");

  let op: UserOperation | undefined;
  try {
    const opValues = params && JSON.parse(params);
    if (opValues) {
      const values = JSON.stringify(opValues);
      op = UserOperationSchema.parse(values);
    }
  } catch (e) {
    console.error("error", e);
  }
  return op;
}
